
import { useI18n } from 'vue-i18n'
import { computed, defineComponent } from 'vue'
import { isMobile, isEverpayApp, everpayAppHost, isEverpayScan, everpayScanHost, isEverpayFarm, everpayFarmHost } from '@/constants'

export default defineComponent({
  setup () {
    const socials = [
      {
        link: 'https://twitter.com/everVisionHQ',
        name: 'twitter'
      },
      {
        link: 'https://discord.gg/WM5MQZGVPF',
        name: 'discord'
      },
      {
        link: 'https://t.me/FinanceEver',
        name: 'telegram'
      },
      {
        link: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'https://medium.com/everfinance' : 'https://news.ever.vision',
        name: 'medium'
      },
      {
        link: 'https://github.com/everFinance',
        name: 'github'
      }
    ]

    const { t, locale } = useI18n()

    const getComputedFooterEntries = (t: any, locale: string) => {
      return [
        {
          title: t('products'),
          items: [
            {
              title: t('wallet'),
              to: isEverpayApp ? '/' : '',
              link: everpayAppHost
            },
            {
              title: t('swap'),
              to: isEverpayApp ? '/swap' : '',
              link: `${everpayAppHost}/swap`
            },
            {
              title: t('NFT_auction'),
              to: isEverpayApp ? '/nft-auction' : '',
              link: `${everpayAppHost}/nft-auction`
            },
            {
              title: t('v3_farm'),
              to: isEverpayFarm ? '/' : '',
              link: `${everpayFarmHost}`
            },
            {
              title: t('explorer'),
              to: isEverpayScan ? '/' : '',
              link: everpayScanHost
            }
          ]
        },
        {
          title: t('develop'),
          items: [
            {
              title: t('documentation'),
              link: `https://docs.everpay.io/${locale.includes('zh') ? '' : 'en'}`
            },
            {
              title: 'everpay-js',
              link: 'https://github.com/everFinance/everpay-js'
            }
          ]
        },
        {
          title: t('support'),
          items: [
            {
              title: t('help_center'),
              link: 'https://discord.gg/xGfYrNeqT8'
            },
            {
              title: t('privacy_policy'),
              link: 'https://eokptpbng3aag6gn7bgpe2mjfhtedjslj44xgm7bpxqtghnc43rq.arweave.net/I5T5vC02wAN4zfhM8mmJKeZBpktPOXMz4X3hMx2i5uM'
            }
            // {
            //   title: t('faqs'),
            //   link: locale.includes('zh')
            //     ? 'https://everpay.zendesk.com/hc/zh-cn/categories/4403074774809-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98'
            //     : 'https://everpay.zendesk.com/hc/en-us/categories/4403074774809-FAQs'
            // }
          ]
        },
        {
          title: t('about'),
          items: [
            {
              title: t('light_paper'),
              link: locale.includes('zh')
                ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
                : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
            },
            {
              title: 'everVision',
              link: 'https://ever.vision/#/'
            }
          ]
        }
      ]
    }

    const footerEntries = computed(() => {
      return getComputedFooterEntries(t, locale.value)
    })

    const year = new Date().getFullYear()

    return {
      isMobile,
      footerEntries,
      socials,
      year,
      t
    }
  }
})

