import { isProd } from '@/constants'
import { sendRequest } from '@/libs/everpay-js/api'
import { LockersInfo, ManualBurnTokens } from '@/store/state'
import redstone from 'redstone-api'
import { Txs, TxNum, TestArIdTxs, AllBundleTxs, AllBalances, SearchForList, AllTxs, TokenList } from '@/pages/interface'
import { ChainType, Token } from './everpay-js/types'
import { GetEverpayBalancesParams, GetEverpayBalancesResult } from './everpay-js/types/api'
import { ManualSubmitData } from './types'
const baseUrl = isProd ? 'https://api-explorer.everpay.io' : 'https://api-explorer-dev.everpay.io'
const testUrl = isProd ? 'https://api-explorer.everpay.io' : 'https://api-explorer-dev.everpay.io' // http://3.127.134.243:8080
// 无问题
export const getLockerInfo = async (): Promise<LockersInfo> => {
  const url = `${testUrl}/info`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

interface UsersNum {
  walletNum: number
  eidWalletNum: number
  arWalletNum: number
  evmWalletNum: number
}
// 图表数据计算有误差，无法计算占有百分比，数据若太小，很可能会忽略，100% ，0%，0%
export const getUsersNum = async (): Promise<UsersNum> => {
  const url = `${testUrl}/tradeInfo`
  const result = await sendRequest({
    method: 'GET',
    url,
    timeout: 30000
  })

  return result.data
}

// 无问题，替换即可
export const getTxNum = async (): Promise<TxNum> => {
  const url = `${testUrl}/txNum`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// 首页完成， Txs 完成
export const getAllTxs = async (page: number = 0, count: number = 20): Promise<AllTxs> => {
  const url = `${testUrl}/txs?count=${count}&cursor=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// tx everhash 页完成
export const getTxByEverHash = async (everhash: string): Promise<Txs> => {
  const url = `${testUrl}/tx/${everhash}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// arId 页
export const getTxsArId = async (arId: string, page: number = 0, count: number = 20): Promise<AllTxs> => {
  const url = `${testUrl}/txs/${arId}?count=${count}&cursor=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// 首页完成， arIdTxs 完成
export const getArIdTxs = async (page: number = 0, count: number = 20): Promise<TestArIdTxs> => {
  const url = `${testUrl}/arTxs?count=${count}&cursor=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// account 页面 Txs 问题：页面数据重复
export const getTxsByAccount = async (account: string, page: number = 0, tag: string = ''): Promise<AllTxs> => {
  const url = `${testUrl}/txsByAccount/${account}?count=20&cursor=${page}${(tag.length > 0) ? `&tokenTag=${tag}` : ''}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
// account 页面 Bundle 问题：接口缺少参数 Rollup tx，status
export const getBundleTxsByAccount = async (account: string, page: number = 0, tag: string = ''): Promise<AllBundleTxs> => {
  const url = `${testUrl}/bundleTxsByAccount/${account}?count=20&cursor=${page}${(tag.length > 0) ? `&tokenTag=${tag}` : ''}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

// 指定 Token 交易列表页面 完成
export const getTxsByTokenTag = async (tag: string, page: number = 0): Promise<AllTxs> => {
  const url = `${testUrl}/txsByToken/${tag}?count=20&cursor=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
// 指定 Token 交易列表页面 bundle 完成
export const getBundleTxsByToken = async (tag: string, page: number = 0): Promise<AllBundleTxs> => {
  const url = `${testUrl}/bundleTxsByToken/${tag}?count=20&cursor=${page}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getAccountBalances = async (account: string): Promise<AllBalances[]> => {
  const url = `${baseUrl}/balances/${account}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
// 获取账户资产，更新接口
export const getEverpayBalances = async ({
  account
}: GetEverpayBalancesParams): Promise<GetEverpayBalancesResult> => {
  const url = `${testUrl}/balances/${account}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
// 获取 token List
export const getTokenListForScan = async (page: number = 0, count: number = 0): Promise<TokenList> => {
  const url = `${testUrl}/tokenList?cursor=${page}&count=${count}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const searchForScan = async (str: string): Promise<SearchForList> => {
  const url = `${testUrl}/search/${str}`
  const result = await sendRequest({
    url,
    method: 'GET'
  })

  return result.data
}

interface CurrencyPriceItem {
  symbol: string
  price: string
}

export const getMarketPrices = async (currency: string, tokens: Token[]): Promise<CurrencyPriceItem[]> => {
  const symbols = tokens.map(t => t.symbol)
  const priceStack = await redstone.getPrice(symbols)
  const tokenArr: Array<{symbol: any, price: string}> = []
  return symbols.map(symbol => {
    const token = {
      symbol,
      price: priceStack[symbol] != null && (symbol !== 'FRA' && symbol !== 'ARG') ? priceStack[symbol].value.toString() : '0'
    }
    if (symbol === 'AR' || symbol === 'USDC') {
      tokenArr.push({ symbol: `w${token.symbol}`, price: token.price })
    }
    return token
  }).concat(tokenArr)
}

export const getAnsResolver = async (address: string): Promise<{domain?: string, address?: string}> => {
  const addr = address.replace(/\.ar\s*$/, '')
  const url = `${testUrl}/resolve/${addr}`

  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const getManualSubmitData = async (chainType: ChainType, everhash: string): Promise<ManualSubmitData> => {
  const url = `https://exechub${isProd ? '' : '-dev'}.everpay.io/v1/evm/callData/${chainType}/${everhash}`

  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}
export const getmanualBurnTokens = async (): Promise<ManualBurnTokens[]> => {
  const url = `${testUrl}/manualBurnTokens`

  const result = await sendRequest({
    url,
    method: 'GET'
  })
  return result.data
}

export const submitManualHash = async (everhash: string, txeverHash: string): Promise<ManualBurnTokens[]> => {
  const url = `${testUrl}/updateTgChain/${everhash}/${txeverHash}`

  const result = await sendRequest({
    url,
    method: 'POST'
  })
  return result.data
}
